import React from 'react';
import InscriptionConfirmationImage from '../assets/confirmation.webp';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageWrapper = styled.div`
    overflow-x: hidden;
`
const LandscapeWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    display: flex;
    align-items: center;
    padding: 5%;
    color: #fff;
    line-height: 1.5em;
    top: 0;
    left: 0;
`
const LandscapeContainer = styled.div`
    width: 60%;
    @media (max-width: 700px) {
        width: 95% !important;
    }
`
const FormWrapper = styled.div`
    z-index: 1;
    position: relative;
    margin-top: -100px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (max-width: 496px) {
        width: 90%;
    }
    @media (max-width: 400px) {
        width: 95%;
        padding: 25px 5px;
    }
`

export default function InscriptionConfirmation() {
    const router = useNavigate()

  const continueShopping = () => {
    // Add any specific actions you want to perform when continuing shopping
    router("/")
  };

  return (
   <PageWrapper>
    <div className="w-100 d-flex align-items-center justify-content-center">
      <FormWrapper>
            <img
                src={InscriptionConfirmationImage}
                width={100}
                height={100}
                alt="Order Confirmation"
                className="my-6"
              />
              <p className="my-3 text-center">
                Votre inscription a été prise en compte, merci pour votre serviabilité !
              </p>
          </FormWrapper>
    </div>
     
   </PageWrapper>
  );
}

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyle from './utils';
import InscriptionConfirmation from './pages/InscriptionConfirmation';

//import RhForm from './pages/RhForm';

const RhForm = lazy(() => import('./pages/RhForm'))

const App = () => {
  return(
    <React.StrictMode>
      <GlobalStyle/>
      <Router>
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route exact path='/' element={<RhForm/>} />
            <Route exact path='/validation' element={<InscriptionConfirmation/>} />
          </Routes>
        </Suspense>
      </Router>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
